import React from "react"
import { graphql, Link } from "gatsby"
import Img from "gatsby-image"
import SVG from "react-inlinesvg"
import { useQueryParam, StringParam } from "use-query-params"

import {
  BulletListWithIcon,
  CheckatradeInfo,
  Faq,
  Layout,
  Seo,
} from "../components/Shared"
import {
  GoogleReviews,
  ThermalImaging,
  GetANewBoiler,
} from "../components/Sections"

import BlobWide from "../images/Blob-wide.svg"

const Service = ({ data }) => {
  const service = data.service
  const landlordSlug = data.landlordServicesSlug.slug
  const [adWordTitle] = useQueryParam("title", StringParam)
  const [adWordMetaTitle] = useQueryParam("meta-title", StringParam)
  const [adWordSubHeading] = useQueryParam("sub-heading", StringParam)
  return (
    <Layout>
      <Seo
        url={
          data.site && data.site.siteMetadata.url + "/services/" + service.slug
        }
        title={
          adWordMetaTitle ? adWordMetaTitle : service.seo && service.seo.title
        }
        description={service.seo && service.seo.metaDescription.metaDescription}
        image={
          service.seo &&
          service.seo.image &&
          service.seo.image.file &&
          service.seo.image.file.url
        }
      />
      <header className="wrapper-width grid-col-2 header header-service">
        <div className="header-right">
          <div className="header-service-illustration">
            <BlobWide />
            <div className="illustration">
              <SVG src={service.illustration.file.url} />
            </div>
          </div>
        </div>
        <div className="header-left">
          <h1 className="heading-extra-large">
            {adWordTitle ? adWordTitle : service.headerTitle}
          </h1>
          <BulletListWithIcon bullets={service.headerBullets} />
          <CheckatradeInfo />
        </div>
      </header>
      <a href="tel: 0191 406 0888" className="service-contact">
        <div className="wrapper-width">
          <span className="service-contact-heading">Call us now</span>
          <span className="service-contact-number">0191 406 0888</span>
        </div>
      </a>
      <GoogleReviews />
      {service?.subServices && service?.subServices?.length > 0 && (
        <section className="wrapper-width wrapper-height service-offerings">
          <h2 className="heading-large">
            {adWordSubHeading
              ? adWordSubHeading
              : service.offeringsSectionTitle}
          </h2>
          <p className="service-offerings-introduction">
            {service.offeringsSectionText.offeringsSectionText}
          </p>
          <section className="grid-col-3">
            {service.subServices.map((offering) => (
              <Link
                to={`/services/${service.slug}/${offering.slug}`}
                className="service-offering"
                key={offering.contentful_id}
              >
                <Img
                  className="service-offering-photo"
                  fluid={offering.photo.fluid}
                  alt={offering.photo.title}
                />
                <div className="service-offering-body">
                  <h3 className="heading-small">{offering.name}</h3>
                  <BulletListWithIcon bullets={offering.headerBullets} />
                  <span className="link-with-icon illustrated-option-link-icon service-offering-body-button">
                    <span className="icon-arrow-right" />
                    <span className="link-with-icon-text">Find out more</span>
                  </span>
                </div>
              </Link>
            ))}
            <div className="service-request-callback">
              <Link to="/contact-us" className="button-primary">
                Contact us
              </Link>
              <br />
              {service.contentful_id !==
                data.landlordServicesSlug.contentful_id && (
                <Link
                  to={`/services/${landlordSlug}`}
                  className="service-offering-landlord-link"
                >
                  {service.headerTitle} for Landlords
                </Link>
              )}
            </div>
          </section>
        </section>
      )}
      <GetANewBoiler backgroundColour="grey-lightest" />
      <section className="wrapper-width wrapper-height">
        {service.faqs && service.faqs.length > 0 && (
          <section className="faq-section service-faqs service-faqs-no-padding-bottom">
            <h2 className="heading-large">Frequently asked questions</h2>
            <div className="faqs">
              {service.faqs.map((faq) => (
                <Faq key={faq.id} question={faq.question}>
                  {faq.answer.answer}
                </Faq>
              ))}
            </div>
          </section>
        )}
      </section>
      <ThermalImaging />
    </Layout>
  )
}

export default Service

export const pageQuery = graphql`
  query getServicePageDetails($id: String!) {
    site {
      siteMetadata {
        url
      }
    }
    service: contentfulServicePages(contentful_id: { eq: $id }) {
      headerTitle
      name
      contentful_id
      slug
      headerBullets
      illustration {
        description
        file {
          url
        }
      }
      offeringsSectionTitle
      offeringsSectionText {
        offeringsSectionText
      }
      subServices {
        photo {
          title
          fluid {
            ...GatsbyContentfulFluid
          }
        }
        name
        headerBullets
        contentful_id
        slug
      }
      faqs {
        answer {
          answer
        }
        question
        id
      }
      seo {
        title
        metaDescription {
          metaDescription
        }
        image {
          file {
            url
          }
        }
      }
    }
    landlordServicesSlug: contentfulServicePages(
      contentful_id: { eq: "59vAPFQXIKGKPpVNn4lhep" }
    ) {
      contentful_id
      slug
    }
  }
`
